/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

import qs from 'qs';

export default {
	name: 'portalMixins',
	
	data() {
		return {
			globalLoading: false,
			msCacheOptions: [
				{
					text: '10 seconds',
					value: 10000
				},
				{
					text: '30 seconds',
					value: 30000
				},
				{
					text: '1 minute',
					value: 60000
				},
				{
					text: '5 minutes',
					value: 5 * 60000
				},
				{
					text: '10 minutes',
					value: 10 * 60000
				},
				{
					text: '30 minutes',
					value: 30 * 60000
				},
				{
					text: '1 hour',
					value: 3600000
				},
				{
					text: '2 hours',
					value: 7200000
				},
				{
					text: '7 hours',
					value: 7 * 3600000
				},
				{
					text: '1 day',
					value: 24 * 3600000
				},
				{
					text: '1 week',
					value: 7 * 24 * 3600000
				},
				{
					text: '1 month',
					value: 30 * 24 * 3600000
				}
			],
			secondsCacheOptions: [
				{
					text: '10 seconds',
					value: 10
				},
				{
					text: '30 seconds',
					value: 30
				},
				{
					text: '1 minute',
					value: 60
				},
				{
					text: '5 minutes',
					value: 5 * 60
				},
				{
					text: '10 minutes',
					value: 10 * 60
				},
				{
					text: '30 minutes',
					value: 30 * 60
				},
				{
					text: '1 hour',
					value: 3600
				},
				{
					text: '2 hours',
					value: 7200
				},
				{
					text: '7 hours',
					value: 7 * 3600
				},
				{
					text: '1 day',
					value: 24 * 3600
				},
				{
					text: '1 week',
					value: 7 * 24 * 3600
				},
				{
					text: '1 month',
					value: 30 * 24 * 3600
				}
			],
			mfaSettings: [
				{
					text: 'Enabled',
					value: true
				},
				{
					text: 'Disabled',
					value: false
				},
				{
					text: 'Account Default',
					value: "default"
				},
			],
			mfaConf: [
				{
					text: 'Use the M360API (default)',
					value: true
				},
				{
					text: 'I have my custom notification server',
					value: false
				}
			]
		}
	},
	
	components: {},
	
	computed: {
		isAuthenticated() {
			return !!this.$store.getters['auth/getToken'];
		},
		currentUser() {
			return this.$store.getters['auth/getUser'];
		},
		currentToken() {
			return this.$store.getters['auth/getToken'];
		},
		globalMessages() {
			return this.$store.getters['globalMessages/list'];
		}
	},
	
	methods: {
		
		openHelpLink(section) {
			if (this.$helpLinks && this.$helpLinks[section]) {
				window.open(this.$helpLinks[section])
			}
		},
		
		getCacheHumanFromMS: function (ms) {
			let text = '';
			this.msCacheOptions.forEach((oneMs) => {
				if (oneMs.value === ms) {
					text = oneMs.text;
				}
			});
			return text;
		},
		
		/* eslint-disable */
		validEmail: function (email) {
			var re = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
			return re.test(email);
		},
		/* eslint-enable */
		
		validPassword: function (pass) {
			var re = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\s\*.!@$%\^&(){}\[\]:;<>,.?\/~_+\-=|\\]).{10,32}$/;
			return re.test(pass);
		},
		
		redirectIfAuthenticated: function (destination) {
			this.globalLoading = true;
			if (!destination) {
				destination = {name: "Dashboard", params: {envCode: 'portal'}};
			}
			if (this.isAuthenticated) {
				this.$router.push(destination);
			} else {
				this.globalLoading = false;
			}
		},
		
		returnMenuItemRoute: function (item, $event) {
			if (item.click) {
				return null;
			} else {
			
			}
		},
		
		handleMenuItem: function (item, $event) {
			$event.preventDefault();
			if (item.click) {
				this[item.click]();
			} else {
				this.goToPage(item);
			}
		},
		
		goToPage: function (myRoute) {
			let page = this.getPage(myRoute);
			if (page) {
				this.$router.push(page);
			}
		},
		
		getPage: function (myRoute) {
			let routeData = this.$router.resolve({name: myRoute.route});
			
			if (routeData && this.$router.currentRoute.name !== routeData.route.name) {
				if (this.canAccess(routeData.route.meta.acl)) {
					
					if (!myRoute.params) {
						myRoute.params = {};
					}
					myRoute.params.envCode = this.envSelected.value;
					
					return {name: routeData.route.name, params: myRoute.params || {}};
				}
			}
		},
		
		updateMenuEntries: async function (menu) {
			for (let i = menu.length - 1; i >= 0; i--) {
				let envCode = this.envSelected.value.toLowerCase();
				if (menu[i].route) {
					let routeData = this.$router.resolve({name: menu[i].route});
					
					if (routeData && routeData.route) {
						if (routeData.route && routeData.route.meta && routeData.route.meta.acl && routeData.route.meta.acl.envCode) {
							envCode = routeData.route.meta.acl.envCode;
						}
						let acl = await this.$acl.isAuthenticated(routeData.route, envCode);
						if (!acl) {
							menu.splice(i, 1);
						}
					}
				} else if (menu[i].children) {
					menu[i].children = await this.updateMenuEntries(menu[i].children);
					
					if (menu[i].children.length === 0) {
						menu.splice(i, 1);
					}
				}
			}
			
			if (menu && menu[0] && menu[0].divider) {
				menu.shift();
			}
			return menu;
		},
		
		canAccess: function (context) {
			let envCode = (context.envCode) ? context.envCode : this.envSelected.value;
			return this.$acl.hasComponentAccess(context, envCode);
		},
		
		canAccessField: function (context) {
			let envCode = (context.envCode) ? context.envCode : this.envSelected.value;
			return this.$acl.hasFieldAccess(context, envCode);
		},
		
		filterFields(payload, config) {
			for (let key in payload) {
				if (Object.hasOwnProperty.call(config, key)) {
					if (config[key] === false) {
						delete payload[key];
					}
				}
			}
		},
		
		clearMessages() {
			this.$store.dispatch('globalMessages/clear');
		},
		
		pushMessage(context) {
			this.$store.dispatch('globalMessages/push', {
				type: context.type,
				title: context.title,
				text: context.text
			});
			this.scrollToTop();
		},
		
		scrollToTop() {
			window.scrollTo(0, 0);
		},
		
		copyTextToClipboard(divId) {
			clearSelection();
			
			if (document.selection) {
				var range = document.body.createTextRange();
				range.moveToElementText(document.getElementById(divId));
				range.select().createTextRange();
				document.execCommand("copy");
				clearSelection();
			} else if (window.getSelection) {
				var range = document.createRange();
				range.selectNode(document.getElementById(divId));
				window.getSelection().addRange(range);
				document.execCommand("copy");
				clearSelection();
			}
			
			function clearSelection() {
				if (window.getSelection) {
					if (window.getSelection().empty) { // Chrome
						window.getSelection().empty();
					} else if (window.getSelection().removeAllRanges) { // Firefox
						window.getSelection().removeAllRanges();
					}
				} else if (document.selection) { // IE?
					document.selection.empty();
				}
			}
		},
		
		copyCodeToClipboard(divId) {
			let codeToCopy = document.querySelector('#' + divId);
			codeToCopy.setAttribute('type', 'text');   // 不是 hidden 才能複製
			codeToCopy.select();
			
			try {
				const successful = document.execCommand('copy');
				if (!successful) {
					alert('Oops, unable to copy');
				}
			} catch (err) {
				alert('Oops, unable to copy');
			}
			
			/* unselect the range */
			codeToCopy.setAttribute('type', 'hidden');
			window.getSelection().removeAllRanges()
		},
		
		executeRequest(context) {
			const self = this;
			const APIHeaders = context.headers || {};
			
			const token = this.$store.getters['auth/getToken'];
			if (token && token.access_token) {
				APIHeaders['Authorization'] = token.access_token;
			}
			
			const project = this.$store.getters['project/get'];
			if (project) {
				APIHeaders['Project'] = project;
			}
			
			if (!APIHeaders['env']) {
				APIHeaders['env'] = (this.envSelected) ? this.envSelected.value : 'portal';
			}
			
			const requestOptions = {
				url: context.url,
				method: (context.method || 'GET').toUpperCase(),
				headers: APIHeaders,
			};
			
			if (!['POST', 'PATCH', 'PUT'].includes(requestOptions.method)) {
				requestOptions.params = context.params;
			} else {
				requestOptions.data = context.params;
			}
			if (context.body) {
				requestOptions.data = context.body;
			}
			
			requestOptions.paramsSerializer = function (params) {
				return qs.stringify(params, {arrayFormat: 'repeat'});
			};
			
			return this.$http(requestOptions).then(response => {
				if (!response.data.data) {
					console.error('Invalid response format: ' + JSON.stringify(response.data));
					return response.data;
				}
				return response.data.data;
			}).catch(error => {
				// normalize error
				const errorRes = {};
				if (error.response) {
					if (error.response.status) errorRes.status = error.response.status;
					if (error.response.data) {
						if (error.response.data.errors) {
							errorRes.errors = error.response.data.errors;
						} else if (error.response.data.error) {
							const code = Object.keys(error.response.data.statusCode)[0];
							errorRes.errors = {};
							errorRes.errors[code] = {
								code,
								message: error.response.data.message
							};
						} else if (error.response.data.forceLogout) {
							self.forceLogout({
								'500': {
									code: 500,
									message: 'Access Denied!'
								}
							});
						}
					}
				}
				if (!errorRes.status) errorRes.status = 500;
				if (!errorRes.errors) errorRes.errors = {
					'500': {
						code: 500,
						message: 'An internal error occurred'
					}
				};
				const code = Object.keys(errorRes.errors)[0];
				errorRes.message = errorRes.errors[code].message;
				// throw normalized error
				throw errorRes;
			});
		},
		
		forceLogout(errors) {
			const self = this;
			self.$store.dispatch('auth/clear');
			self.$store.dispatch('env/clear');
			self.$cookies.remove('token');
			self.clearMessages();
			
			if (self.$router.currentRoute.name !== 'Login') {
				self.$router.push({name: "Login"});
			}
			
			setTimeout(() => {
				self.printErrors(errors);
			}, 1000);
		},
		
		printErrors(errors) {
			const self = this;
			for (let code in errors) {
				self.$store.dispatch('globalMessages/push', {
					type: 'error',
					title: `Error ${code}`,
					text: errors[code].message
				});
			}
			self.scrollToTop();
		},
		
		async getSendData(context) {
			const self = this;
			if (!context.noLoading) {
				self.$emit('triggerOverlayLoading', true);
			}
			self.clearMessages();
			return self.executeRequest(context).then(data => data).catch(error => {
				if (error.status === 520 && error.errors['142']) {
					if (context.url === '/logout') {
						self.forceLogout(error.errors);
						throw error;
					} else {
						// load existing expired token
						const token = self.$store.getters['auth/getToken'];
						
						// refresh access token
						if (token && token.refresh_token) {
							return self.executeRequest({
								url: '/token',
								method: 'put',
								params: {
									'refresh': token.refresh_token
								}
							}).then(async dataRes => {
								// save new token
								self.$store.dispatch('auth/setToken', dataRes);
								// retry original request
								return await self.getSendData(context);
							}).catch(error => {
								const token = self.$store.getters['auth/getToken'];
								if (!token || !token.access_token || (error.errors && error.errors['140'] && error.errors['140'].message === 'Invalid Refresh Token Provided!')) {
									self.forceLogout(error.errors);
								}
								throw error;
							});
						} else {
							self.forceLogout(error.errors);
							throw error;
						}
					}
				} else {
					if (!context.skipErrors) {
						self.printErrors(error.errors);
					}
					throw error;
				}
			}).finally(() => {
				if (!context.noLoading) {
					self.$emit('triggerOverlayLoading', false);
				}
			});
		}
		
	}
}
